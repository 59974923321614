<template>
  <div style="height: 100%">
    <v-row class="hidden-md-and-up" style="height:75px">
      <v-col cols="12" class="voucher-mobile-header text-center">
        {{ $t(`label.claimVoucher`) }}
      </v-col>
    </v-row>
    <v-card class="" :class="$vuetify.breakpoint.mdAndUp ? 'desktop_voucher_card ml-4' : 'desktop_card'">
      <v-card-title class="hidden-sm-and-down">
        <label class="--v-primary-base text-left voucher-desktop-txt text-capitalize mx-4">{{ $t(`label.claimVoucher`) }}</label>
      </v-card-title>
      <v-card :class="$vuetify.breakpoint.mdAndUp ? '' : 'bank-detail-card'" class="voucher-card" elevation="0">
        <v-row no-gutters :class="$vuetify.breakpoint.mdAndUp ? 'px-8' : 'pa-4'">
          <v-col cols="10" class="hidden-sm-and-down">
            <label class="input-field-label ma-0 text-capitalize d-block pb-2">
              {{ $t(`label.haveVoucher`) }}
            </label>
          </v-col>
          <v-col cols="12" class="hidden-md-and-up">
            <label class="input-field-label ma-0 text-capitalize d-block pb-2">
              {{ $t(`label.applyVoucher`) }}
              <span class="red--text ml-1">*</span>
            </label>
          </v-col>
          <v-col md="5">
            <v-form ref="codeForm">
              <app-form-field v-model.trim="voucherObj.code" class="voucher-box" :placeholder="$t(`label.voucherCode`)" :rules="validator.requiredRules(4, 20)"></app-form-field>
            </v-form>
          </v-col>
          <v-col md="2" class=" pl-5 hidden-sm-and-down">
            <v-btn class="dialog-button voucher-checking" @click="this.onClaimVoucherDesktopClick" :readonly="!codeIsValid">{{ $t(`label.apply`) }}</v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8 pa-4 hidden-md-and-up">
          <v-col lg="4" md="5">
            <app-button class="dialog-button submit-btn" :action="this.onClaimVoucherClick" :readonly="!codeIsValid" :title="$t(`button.submit`)"></app-button>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="hidden-sm-and-down voucher-border">
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-col cols="12" class="hidden-sm-and-down px-8 pb-8">
          <label class="input-field-label ma-0 text-capitalize d-block pb-2">
            {{ $t(`label.voucherDetails`) }}
          </label>
        </v-col>
        <v-row no-gutters align="end" v-if="voucherCheckComplete" class="hidden-sm-and-down pb-5 px-8">
          <!--        <v-row no-gutters align="end" v-if="voucherCheckComplete && voucherCheck" class="hidden-sm-and-down pb-5 px-8">-->
          <v-col md="10" class="pb-2">
            <label class="field-name-desktop">{{ $t(`field.bonusAmount`) }}:</label>
          </v-col>
          <v-col md="4" class="pa-2 filled-label-voucher" v-if="voucherCheck.data.bonusIsPercentage">
            {{ voucherCheck.data.bonusPercentage }}
          </v-col>
          <v-col md="5" class="pa-2 filled-label-voucher" v-else>
            {{ voucherCheck.data.bonusGivenAmount }}
          </v-col>
          <v-col md="10" class="py-2">
            <label class="field-name-desktop">{{ $t(`field.targetTurnover`) }}:</label>
          </v-col>
          <v-col md="5" class="pa-2 filled-label-voucher">
            {{ voucherCheck.data.bonusTargetRollover }}
          </v-col>
          <v-col md="10" class="py-2">
            <label class="field-name-desktop">{{ $t(`field.voucherExpires`) }}:</label>
          </v-col>
          <v-col md="5" class="pa-2 filled-label-voucher">
            {{ voucherCheck.data.voucher_expires }}
          </v-col>
          <v-col md="10" class="py-2">
            <label class="field-name-desktop">{{ $t(`field.turnoverRequirements`) }}:</label>
          </v-col>
          <v-col md="5" class="pa-2 filled-label-voucher">
            {{ voucherCheck.data.deposit_needed }}
          </v-col>
          <v-col cols="10" class="pt-6">
            <v-btn height="auto" @click="applyVoucher" color="#FFCE01" depressed class="voucher-width primary-button theme-button text-capitalize pa-2 black--text">
              {{ $t(`label.claim`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <!-- <app-dialog :dialogShow="msgDialog.show" :max-width="1100" :title="msgDialog.title" :closeAction="this.onCloseClick">
      <v-container :class="[$vuetify.breakpoint.mdAndDown ? 'px-3' :'px-5', $vuetify.breakpoint.smAndDown ? 'maxFrameHeight' :'fill-height']">
        <div v-html="msgDialog.msg" class="cms_bonus_content"></div>
        <v-btn class="full-width font-weight-bold pa-2 buttonPrimary primary-button theme--light" @click="onCloseClick()">{{ $t(`label.ok`) }}
        </v-btn>
      </v-container>
    </app-dialog> -->
    <app-dialog :dialogShow="msgDialog.show" ref="claimVoucherDetailDialog" :max-width="400" :title="voucherObj.code" :header_title="'editProfile'" :closeAction="this.onCloseClick">
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="6">
          <label class="voucher-claim-dialog-title">{{ $t(`field.bonusAmount`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right" v-if="voucherCheck.data.bonusIsPercentage">
          {{ voucherCheck.data.bonusPercentage }}
        </v-col>
        <v-col cols="6" class="text-right" v-else>
          {{ voucherCheck.data.bonusGivenAmount }}
        </v-col>
        <v-col cols="6" class="mt-2 mb-6">
          <label class="voucher-claim-dialog-title">{{ $t(`field.targetTurnover`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right mt-2 mb-6">
          {{ voucherCheck.data.bonusTargetRollover }}
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="applyVoucher" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">{{ $t(`label.confirm`) }}</v-btn>
        </v-col>
      </v-row>
    </app-dialog>
  </div>
</template>

<script>
import AppGameCard from '../../components/home/GameCard'
import { formValidator, locale, errorCodeHelper } from '@/util'
import { VOUCHER_BONUS_APPLY, VOUCHER_BONUS_APPLY_RESET, VOUCHER_BONUS_CHECK, VOUCHER_BONUS_CHECK_MOBILE, VOUCHER_BONUS_CHECK_MOBILE_RESET, VOUCHER_BONUS_CHECK_RESET } from '../../store/bonus.module'
export default {
  name: 'claimVoucher',
  components: {
    AppGameCard
  },
  data: () => ({
    validator: formValidator,
    voucherObj: {
      code: ''
    },
    msgDialog: {
      show: false,
      title: '',
      msg: ''
    },
    voucherCheckComplete: false,
    codeIsValid: false,
    alertShow: false,
    alertSuccess: true,
    alertMessage: '',
    voucherCheck: {
      data: {}
    }
  }),
  computed: {
    voucherCheckResult() {
      return this.$store.state.bonus.checkVoucherResponse.complete
    },
    voucherCheckResultMobile() {
      return this.$store.state.bonus.checkVoucherMobileResponse.complete
    },
    voucherApplyResult() {
      return this.$store.state.bonus.applyVoucherResponse.complete
    },
    voucherCode() {
      return this.voucherObj.code
    }
  },
  watch: {
    voucherApplyResult() {
      if (this.$store.state.bonus.applyVoucherResponse.complete) {
        this.openResultDialog(this.$store.state.bonus.applyVoucherResponse)
      }
    },
    voucherCheckResult() {
      if (this.$store.state.bonus.checkVoucherResponse.complete) {
        this.voucherDesktop = this.$store.state.bonus.checkVoucherResponse
        this.showResponse(this.voucherDesktop)
      }
    },
    voucherCheckResultMobile() {
      if (this.$store.state.bonus.checkVoucherMobileResponse.complete) {
        this.voucherCheck = this.$store.state.bonus.checkVoucherMobileResponse
        this.openConfirmDialog(this.voucherCheck)
      }
    }
  },
  methods: {
    onClaimVoucherClick() {
      if (this.$refs.codeForm.validate()) {
        const voucherObj = this.voucherObj
        this.$store.dispatch(`${VOUCHER_BONUS_CHECK_MOBILE}`, {
          voucherObj
        })
      }
    },
    onClaimVoucherDesktopClick() {
      if (this.$refs.codeForm.validate()) {
        const voucherObj = this.voucherObj
        this.$store
          .dispatch(`${VOUCHER_BONUS_CHECK}`, {
            voucherObj
          })
          .then(() => {
            this.$store.dispatch(`${VOUCHER_BONUS_CHECK_RESET}`)
          })
      }
    },
    openResultDialog(result) {
      if (result.code != 0 && !result.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(result.code))
      } else {
        this.$parent.openAlert(true, locale.getMessage(`message.voucherBonusApplied`))
      }
      this.$store.dispatch(`${VOUCHER_BONUS_CHECK_RESET}`)
      this.$store.dispatch(`${VOUCHER_BONUS_CHECK_MOBILE_RESET}`)
      this.$store.dispatch(`${VOUCHER_BONUS_APPLY_RESET}`)
    },
    openConfirmDialog(result) {
      if (result.code != 0 && !result.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(result.code))
        this.$store.dispatch(`${VOUCHER_BONUS_CHECK_RESET}`)
        this.$store.dispatch(`${VOUCHER_BONUS_CHECK_MOBILE_RESET}`)
      } else {
        this.msgDialog.show = true
      }
    },
    showResponse(result) {
      if (result.code != 0 && !result.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(result.code))
        this.voucherCheckComplete = false
      } else {
        this.voucherCheckComplete = false
        this.$parent.openAlert(true, 'Voucher Is Applied')
        this.voucherCheck = result
        this.voucherCheckComplete = true
      }
    },
    applyVoucher() {
      const voucherObj = this.voucherObj
      this.$store.dispatch(`${VOUCHER_BONUS_APPLY}`, {
        voucherObj
      })
      this.msgDialog.show = false
    },
    onCloseClick() {
      this.$store.dispatch(`${VOUCHER_BONUS_CHECK_RESET}`)
      this.$store.dispatch(`${VOUCHER_BONUS_CHECK_MOBILE_RESET}`)
      this.msgDialog.show = false
    }
  }
}
</script>

<style lang="scss">
.desktop_voucher_card {
  border-radius: 12px !important;
  height: 100%;
  font-family: 'Roboto', sans-serif;

  .voucher-desktop-txt {
    font-weight: bold;
  }
}

.voucher-width {
  width: 50% !important;
  border-radius: 32px !important;
}

.filled-label-voucher {
  border: 1px solid #d3d3d3;
  background-color: #f3f5f6;
  color: #6c6c6c;
  font-weight: bold;
  border-radius: 32px;
  height: 40px;
}
.field-name-desktop {
  color: #000;
}

.voucher-box .v-text-field--outlined fieldset {
  border-color: #d3d3d3;
}

.voucher-checking {
  background-color: #0094ff !important;
  height: 40px;
  min-width: 150px !important;
  font-family: 'Roboto', sans-serif !important;
  border-radius: 32px !important;
  font-size: 12px;
  color: white !important;
}

.voucher-border {
  border-color: #d3d3d3;
  border-width: 1px;
}

.voucher-mobile-header {
  background-color: #000000;
  color: var(--v-newMobilePrimary-base);
  margin-top: auto;
  margin-bottom: auto;
}
.voucher-card {
  height: 80%;
}
.voucher-claim-dialog-title {
  color: #acacac;
}

@media (max-width: 959px) {
  .submit-btn.buttonPrimary {
    background-color: var(--v-newMobileSecondary-base) !important;
    color: #ffffff;
  }
}
</style>
